<template>
	<BirdDogDialog
		v-model="computedValue"
		:title="title"
		:loading="$props.loading"
		:width="$props.width"
		:max-width="$props.maxWidth"
	>
		<v-form ref="form" v-model="validForm" :disabled="saving" class="bird-dog-referral-form">
			<h5 class="bird-dog-tracking__subtitle">Prospective B2B Agency Details</h5>

			<v-row dense class="mt-6">
				<v-col cols="12">
					<label for="field-AgencyName" class="bird-dog-tracking__label">{{ mapFormKeys.AgencyName }}<span class="required-mark">*</span></label>
					<v-text-field
						id="field-AgencyName"
						v-model="form.AgencyName"
						:rules="[form_rules.required]"
						required
						class="mt-0 pt-0" :style="fieldStyle"
						@change="form.AgencyName = $event.trim()"
					/>
				</v-col>
			</v-row>

			<v-row dense class="mt-6">
				<p class="bird-dog-tracking__label px-1 full-width">Principal/Contact Info<span class="required-mark">*</span></p>
				<v-col cols="12" sm="6">
					<v-text-field
						v-model="form.PrincipalFirstName"
						label="First Name*"
						:rules="[form_rules.required]"
						required
						@change="form.PrincipalFirstName = $event.trim()"
					/>
				</v-col>
				<v-col cols="12" sm="6">
					<v-text-field
						v-model="form.PrincipalLastName"
						label="Last Name*"
						:rules="[form_rules.required]"
						required
						@change="form.PrincipalLastName = $event.trim()"
					/>
				</v-col>
				<v-col cols="12">
					<v-text-field
						v-model="form.PrincipalEmail"
						label="Email*"
						:rules="[form_rules.required, form_rules.email]"
						required
						@change="form.PrincipalEmail = $event.trim()"
					/>
				</v-col>
				<v-col cols="12">
					<v-text-field
						v-model="form.PrincipalPhone"
						label="Phone*"
						:rules="[form_rules.required, phoneRule]"
						required
						@change="form.PrincipalPhone = $event.trim()"
					/>
				</v-col>
			</v-row>

			<v-row dense class="mt-6">
				<p class="bird-dog-tracking__label px-1 full-width">{{ mapFormKeys.TargetMarkets }}</p>
				<p class="form__note px-1 full-width">Choose all that apply</p>
				<v-col v-for="col in targetMarketsCols" :key="col.join('-')" cols="12" sm="6">
					<v-checkbox v-for="idx in col" :key="targetMarkets[idx].label" v-model="targetMarkets[idx].value" :label="targetMarkets[idx].label" hide-details />
				</v-col>
			</v-row>

			<v-row dense class="mt-8">
				<v-col cols="12" class="d-flex align-end" style="gap:1rem">
					<label for="field-NumOfProducingAgents" class="bird-dog-tracking__label px-1 pb-6">{{ mapFormKeys.NumOfProducingAgents }}:</label>
					<v-text-field id="field-NumOfProducingAgents" v-model.trim.number="form.NumOfProducingAgents" :rules="[form_rules.number]" class="mt-0 pt-0" :style="smallFieldStyle" />
				</v-col>
			</v-row>

			<v-row dense class="mt-3">
				<v-col cols="12" class="d-flex align-end" style="gap:1rem">
					<label for="field-LifeSalesVolume" class="bird-dog-tracking__label px-1 pb-6">{{ mapFormKeys.LifeSalesVolume }}:</label>
					<v-text-field
                        v-model.trim="form.LifeSalesVolume"
                        :rules="[form_rules.currency]"
                        :style="smallFieldStyle"
                        prefix="$"
                        id="field-LifeSalesVolume"
                        class="mt-0 pt-0"
                    />
				</v-col>
			</v-row>

			<v-row dense class="mt-3">
				<v-col cols="12" class="d-flex align-end" style="gap:1rem">
					<label for="field-AnnuitySalesVolume" class="bird-dog-tracking__label px-1 pb-6">{{ mapFormKeys.AnnuitySalesVolume }}:</label>
					<v-text-field
                        v-model.trim="form.AnnuitySalesVolume"
                        :rules="[form_rules.currency]"
                        :style="smallFieldStyle"
                        prefix="$"
                        id="field-AnnuitySalesVolume"
                        class="mt-0 pt-0"
                    />
				</v-col>
			</v-row>

			<v-row dense class="mt-6">
				<v-col cols="12">
					<label for="field-ProspectiveAgencyIsAGoodFit" class="bird-dog-tracking__label">{{ mapFormKeys.ProspectiveAgencyIsAGoodFit }}<span class="required-mark">*</span></label>
					<v-textarea
						id="field-ProspectiveAgencyIsAGoodFit"
						v-model="form.ProspectiveAgencyIsAGoodFit"
						outlined hide-details="auto" :rows="4"
						:rules="[form_rules.required]"
						required
						@change="form.ProspectiveAgencyIsAGoodFit = $event.trim()"
					/>
				</v-col>
			</v-row>

			<v-row dense class="mt-6">
				<v-col cols="12">
					<label for="field-AdditionalInfo" class="bird-dog-tracking__label">{{ mapFormKeys.AdditionalInfo }}</label>
					<v-textarea
						id="field-AdditionalInfo"
						v-model="form.AdditionalInfo"
						outlined hide-details="auto" :rows="4"
						@change="form.AdditionalInfo = $event.trim()"
					/>
				</v-col>
			</v-row>

			<template v-if="!hasAdminPermissions">
				<v-row dense class="mt-6">
					<v-col cols="12">
						<p class="mb-0"><a :href="termsAndConditionsAgreeHref" target="_blank" class="bird-dog-tracking__label bird-dog-tracking__link">Bird-Dog Bonus Terms and Conditions</a></p>
						<v-checkbox v-model="termsAndConditionsAgree" :label="termsAndConditionsAgreeLabel" :rules="[form_rules.required]" required class="mt-0" />
					</v-col>
				</v-row>

				<v-row dense class="mt-6">
					<v-alert v-if="warningMessage" type="warning" class="mb-8 full-width">{{ warningMessage }}</v-alert>
					<v-col class="d-flex">
						<v-btn class="bird-dog-tracking__primary-btn ml-auto" color="q_blue_1" :disabled="!submitEnabled" elevation="0" @click="submit">
							<v-progress-circular v-if="saving" class="mr-2" left size="24" indeterminate />
							Submit
						</v-btn>
					</v-col>
				</v-row>
			</template>

			<template v-if="hasAdminPermissions">
				<p class="bird-dog-tracking__label px-1 mt-10 font-italic">*To be filled out by staff</p>

				<v-row>
					<v-col cols="12">
						<v-select v-model="form.Status" :items="statusOptions" label="Status" hide-details :style="fieldStyle" />
					</v-col>
					<v-col cols="12">
						<v-select v-model="form.Qualified" :items="qualifiedOptions" label="Qualified" hide-details :style="fieldStyle" />
					</v-col>
				</v-row>

				<v-row dense class="mt-6">
					<v-col cols="12">
						<label for="field-PublicNotes" class="bird-dog-tracking__label">{{ mapFormKeys.PublicNotes }}</label>
						<v-textarea
							id="field-PublicNotes"
							v-model="form.PublicNotes"
							outlined hide-details="auto" :rows="4"
							@change="form.PublicNotes = $event.trim()"
						/>
					</v-col>
				</v-row>

				<v-row dense class="mt-6">
					<v-col cols="12">
						<label for="field-InternalNotes" class="bird-dog-tracking__label">{{ mapFormKeys.InternalNotes }}</label>
						<v-textarea
							id="field-InternalNotes"
							v-model="form.InternalNotes"
							outlined hide-details="auto" :rows="4"
							@change="form.InternalNotes = $event.trim()"
						/>
					</v-col>
				</v-row>

				<v-row dense class="mt-8">
					<v-alert v-if="warningMessage" type="warning" class="mb-8 full-width">{{ warningMessage }}</v-alert>
					<v-col class="d-flex">
						<v-btn class="bird-dog-tracking__primary-btn ml-auto" color="q_blue_1" :disabled="!submitEnabled" elevation="0" @click="confirmSave">
							<v-progress-circular v-if="saving" class="mr-2" left size="24" indeterminate />
							Save
						</v-btn>
					</v-col>
				</v-row>
			</template>
		</v-form>

		<v-dialog
			v-model="doShowConfirmationPrompt"
			width="100%"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
			content-class="bird-dog-form-confirmation"
		>
			<v-card>
				<v-card-title>
					<h5 class="confirmation-card__title">{{ confirmationMessage }}</h5>
				</v-card-title>

				<v-card-text>
					<v-simple-table dense class="confirmation-card__table">
						<thead>
							<tr>
								<th>Original</th>
								<th>Edited</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="item in formChanges">
								<tr :key="item.key + '-label'">
									<td colspan="2" class="confirmation-card__table-label">{{ item.label }}</td>
								</tr>
								<tr :key="item.key">
									<td>{{ item.before }}</td>
									<td>{{ item.after }}</td>
								</tr>
							</template>
						</tbody>
					</v-simple-table>
				</v-card-text>

				<v-card-actions>
					<div class="full-width d-flex justify-space-between px-4 pt-4 pb-2">
						<v-btn class="bird-dog-tracking__text-btn" text @click="doShowConfirmationPrompt = false">Cancel</v-btn>
						<v-btn class="bird-dog-tracking__primary-btn ml-auto" color="q_blue_1" elevation="0" @click="save">Save</v-btn>
					</div>
                </v-card-actions>
			</v-card>
		</v-dialog>
	</BirdDogDialog>
</template>

<script>
import BirdDogDialog from './BirdDogDialog.vue'
import { submitReferralForm, saveReferralForm } from '../api/index'
import { statusOptions, qualifiedOptions, targetMarketsOptions } from '../config/birdDogConfigs.js'

const statusOptionsLabels = statusOptions.map(item => item.label)

const mapFormKeys = {
	AgencyName: 'Agency Name',
	PrincipalFirstName: 'Principal First Name',
	PrincipalLastName: 'Principal Last Name',
	PrincipalEmail: 'Principal Email',
	PrincipalPhone: 'Principal Phone',
	TargetMarkets: 'Target Markets',
	NumOfProducingAgents: 'Number of producing agents',
	LifeSalesVolume: 'Life Sales Volume',
	AnnuitySalesVolume: 'Annuity Sales Volume',
	ProspectiveAgencyIsAGoodFit: 'Why do you believe the prospective agency is a good fit for Quility B2B?',
	AdditionalInfo: 'Additional Info',
	Status: 'Status',
	Qualified: 'Qualified',
	PublicNotes: 'Public Notes',
	InternalNotes: 'Internal Notes',
}

const defaultForm = {
	AgencyName: '',
	PrincipalFirstName: '',
	PrincipalLastName: '',
	PrincipalEmail: '',
	PrincipalPhone: '',
	TargetMarkets: [],
	NumOfProducingAgents: null,
	LifeSalesVolume: null,
	AnnuitySalesVolume: null,
	ProspectiveAgencyIsAGoodFit: '',
	AdditionalInfo: '',
	Status: statusOptionsLabels[0],
	Qualified: qualifiedOptions[0].value,
	PublicNotes: '',
	InternalNotes: '',
}

export default {
	components: {
		BirdDogDialog,
    },
	props: {
		value: { type: Boolean, default: false },
		mode: { type: String, default: 'submit' },
		data: { type: Object, default: () => ({}) },
		loading: { type: Boolean, default: false },
		hasAdminPermissions: { type: Boolean, default: false },
		width: String,
		maxWidth: String,
	},
	data () {
		return {
			statusOptions: statusOptionsLabels,
			qualifiedOptions,
			mapFormKeys,

			form: {},
			targetMarkets: [],
			validForm: false,
			saving: false,
			warningMessage: '',

			termsAndConditionsAgree: false,
			termsAndConditionsAgreeLabel: 'I have read, understand and agree to the Bird-Dog Bonus terms and conditions.',
			termsAndConditionsAgreeHref: 'https://hq.quility.com/api/public/document/39233/bird-dog-bonus-terms',

			doShowConfirmationPrompt: false,
			confirmationMessage: 'Are you sure you want to save changes?',
		}
	},
	created () {
		this.form = this.mode === 'submit'
			? { ...defaultForm }
			: {
				...this.data,
				Qualified: this.data.Qualified ? this.getNormalizedQualified(this.data.Qualified) : qualifiedOptions[0].value,
				Status: this.data.Status ? this.getNormalizedStatus(this.data.Status) : statusOptionsLabels[0],
			}

        this.form.AnnuitySalesVolume = this.parseNumberToCurrency(this.form.AnnuitySalesVolume)
        this.form.LifeSalesVolume = this.parseNumberToCurrency(this.form.LifeSalesVolume)

		this.targetMarkets = targetMarketsOptions.map(item => ({
			label: item,
			value: this.form.TargetMarkets.includes(item),
		}))
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		title () {
			return this.mode === 'submit' ? 'Submit New Bird-Dog Referral' : 'Edit Bird-Dog Referral'
		},
		targetMarketsCols () {
			const res = []
			const itemInCol = 3
			const targetMarketsIndexes = this.targetMarkets.map((_, idx) => idx)
			while (itemInCol > 0 && targetMarketsIndexes.length > 0) {
				res.push(targetMarketsIndexes.splice(0, itemInCol))
			}
			return res
		},
		formChanges () {
			const base = this.mode === 'submit' ? defaultForm : this.data
			return Object.keys(defaultForm)
				.map(key => {
					let before = Array.isArray(defaultForm[key]) ? base[key].sort().join(', ') : base[key]
					let after = Array.isArray(defaultForm[key]) ? this.form[key].sort().join(', ') : this.form[key]
                    if (key === 'AnnuitySalesVolume' || key === 'LifeSalesVolume') {
                        before = this.parseNumberToCurrency(before)
                        after = this.parseNumberToCurrency(this.parseCurrencyToNumber(after))
                    }

					if (
						typeof before === 'string' && typeof after === 'string' &&
						before.toLowerCase() === after.toLowerCase()
					) { return false }
					if (before === after) { return false }
					return { key, before, after, label: this.mapFormKeys[key] }
				})
				.filter(Boolean)
		},
		submitEnabled () {
			return this.formChanges.length && !this.doShowConfirmationPrompt && !this.saving
		},
		fieldStyle () {
			return this.$vuetify.breakpoint.xs ? {} : { maxWidth: '25rem' }
		},
		smallFieldStyle () {
			return { maxWidth: '8rem', flexShrink: 0 }
		},
		agentCode () {
			return this.user.Agent.AgentCode
		},
	},
	watch: {
		targetMarkets: {
			deep: true,
			handler () {
				this.form.TargetMarkets = this.targetMarkets
					.map(item => item.value ? item.label : false)
					.filter(Boolean)
			},
		},
	},
	methods: {
		async submit () {
			if (!this.validateForm()) { return }
			if (this.saving) { return }
			this.saving = true
			const form = {
				...this.form,
				...(this.form.AnnuitySalesVolume && { AnnuitySalesVolume: this.form.AnnuitySalesVolume.replaceAll(",", "") }),
				...(this.form.LifeSalesVolume && { LifeSalesVolume: this.form.LifeSalesVolume.replaceAll(",", "") }),
			};
			const res = await submitReferralForm(this.agentCode, form)
			if (res.error) {
				this.showError(`Oops! There was a problem submitting Bird-Dog Referral Form.<br>`, res.message)
				console.error(res.error)
			} else {
				this.$emit('submit')
				this.computedValue = false
			}
			this.saving = false
		},
		confirmSave () {
			if (!this.validateForm()) { return }
			this.doShowConfirmationPrompt = true
		},
		async save () {
			this.doShowConfirmationPrompt = false
			if (this.saving) { return }
            this.form.AnnuitySalesVolume = this.parseCurrencyToNumber(this.form.AnnuitySalesVolume)
            this.form.LifeSalesVolume = this.parseCurrencyToNumber(this.form.LifeSalesVolume)
			this.saving = true
			const res = await saveReferralForm(this.agentCode, this.form)
			if (res.error) {
				this.showError(`Oops! There was a problem saving Bird-Dog Referral Form.<br>`, res.message)
				console.error(res.error)
			} else {
				this.$emit('save')
				this.computedValue = false
			}
			this.saving = false
		},
		validateForm () {
			if (!this.$refs['form'].validate()) {
                this.warningMessage = 'Please correct invalid fields.'
                setTimeout(() => { this.warningMessage = '' }, 6000)
                return false
            }
			return true
		},
		phoneRule (v) {
			const cleaned = String(v).replace(/\D/g, '')
			if (Number.isInteger(Number(cleaned)) && cleaned.length >= 10) { return true }
			return 'Please enter a valid phone number.'
		},
		getNormalizedQualified (qualified) {
			return qualifiedOptions.find(({ value }) => value.toLowerCase() === qualified?.toLowerCase?.())?.value || qualified
		},
		getNormalizedStatus (status) {
			return statusOptionsLabels.find(item => item.toLowerCase() === status?.toLowerCase?.()) || status
		},
        parseCurrencyToNumber(str) {
            return typeof str === 'string' ? Number(str.replaceAll(',', '')) : str
        },
        parseNumberToCurrency(sum) {
            return typeof sum === 'number' ? sum.toLocaleString('en-US') : sum
        },
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bird-dog-referral-form {
	.form__note {
		margin-bottom: 0;
		font-size: 1rem;
		line-height: 1;
		color: $color-note;
	}
}

.bird-dog-form-confirmation {
	.confirmation-card__title {
		font-size: 1.125rem;
		margin-bottom: 1rem;
		letter-spacing: normal;
		line-height: 1.5;
	}

	.confirmation-card__table {
		th {
			height: 1.5rem !important;
		}

		td:not(.confirmation-card__table-label) {
			width: 50%;
			vertical-align: text-top;
			background-color: $color-notes-bg;
			border-bottom: none !important;
		}

		.confirmation-card__table-label {
			padding-top: 0.5rem;
			font-size: 0.9em;
			font-weight: bold;
			background-color: white;
			border-bottom: none !important;
		}
	}
}
</style>
